import { Fragment, useEffect, useState } from 'react';
import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.ts';
import { MenuAction, menuActions } from './menu-actions.tsx';
import { menu } from './menu.css.ts';

const mobileMenuActions: MenuAction[] = [
  menuActions.Home,
  menuActions.Search,
  menuActions.CreatePost,
  menuActions.Notifications,
  menuActions.More,
];

const tabletMenuActions: MenuAction[] = [
  menuActions.Home,
  menuActions.Tournaments,
  menuActions.Streams,
  menuActions.Notifications,
  menuActions.StreamDeck,
  menuActions.Invites,
  menuActions.Report,
  menuActions.CreatePost,
];

export const Menu = () => {
  const isTablet = useIsTablet();
  const [menuItems, setMenuItems] = useState(
    isTablet ? tabletMenuActions : mobileMenuActions,
  );

  useEffect(() => {
    setMenuItems(isTablet ? tabletMenuActions : mobileMenuActions);
  }, [isTablet]);

  return (
    <div className={menu}>
      {menuItems.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}
    </div>
  );
};
