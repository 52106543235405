import { useIsLaptop, useIsTablet } from '@synoptic/ui-kit/responsive/hooks.js';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@synoptic/ui-kit/tooltip/tooltip.js';
import clsx from 'clsx';
import { FC } from 'react';
import { HeaderLogo } from './header-logo.tsx';
import { Menu } from './menu/menu.tsx';
import { ProfileMenu } from './profile-menu.tsx';
import {
  sidebarBody,
  sidebarCollapseButton,
  sidebarCollapseButtonContainer,
  sidebarWrapper,
  sidebarInner,
  sidebarWrapperCollapsed,
  sidebarCollapsed,
  sidebarCollapseButtonTooltipContent,
  sidebarCollapseButtonTooltipArrow,
} from './sidebar.css.ts';
import { FastArrowRightIcon } from '@synoptic/ui-kit/icons/react/fast-arrow-right.js';
import { IconButton } from '@synoptic/ui-kit/button/icon-button.tsx';
import { useSidebarContext } from './sidebar-context.tsx';

export const Sidebar: FC = () => {
  const isTablet = useIsTablet();
  const isLaptop = useIsLaptop();
  const { isCollapsed, setIsCollapsed } = useSidebarContext();

  return (
    <div
      className={clsx(sidebarWrapper, {
        [sidebarWrapperCollapsed]: isCollapsed,
      })}
    >
      <div className={sidebarInner}>
        {isTablet ? <HeaderLogo /> : null}
        <div className={sidebarBody}>
          {isLaptop && (
            <Tooltip disableHoverableContent>
              <TooltipTrigger asChild>
                <div className={sidebarCollapseButtonContainer}>
                  <IconButton
                    className={clsx(sidebarCollapseButton, {
                      [sidebarCollapsed]: isCollapsed,
                    })}
                    variant="primary"
                    onClick={() => setIsCollapsed((collapsed) => !collapsed)}
                  >
                    <FastArrowRightIcon />
                  </IconButton>
                </div>
              </TooltipTrigger>
              <TooltipContent
                className={sidebarCollapseButtonTooltipContent}
                arrowClassName={sidebarCollapseButtonTooltipArrow}
                side="right"
              >
                {isCollapsed ? 'Expand menu' : 'Collapse menu'}
              </TooltipContent>
            </Tooltip>
          )}
          <Menu />

          {isTablet ? <ProfileMenu /> : null}
        </div>
      </div>
    </div>
  );
};
