import { ClientLoaderFunction } from 'react-router';
import { AppContent } from './app-content';
import { appLoader } from './loader';
import { SidebarProvider } from './sidebar/sidebar-provider';
import { SplashScreen } from './splash-screen';
import { ApolloProvider } from '@apollo/client';
import { getApolloClient } from '@/apollo/apollo-client-instance';

export const clientLoader: ClientLoaderFunction = appLoader;
clientLoader.hydrate = true;

export const HydrateFallback = () => <SplashScreen />;

export default function App() {
  return (
    <SidebarProvider>
      <ApolloProvider client={getApolloClient()}>
        <AppContent />
      </ApolloProvider>
    </SidebarProvider>
  );
}
